import * as React from 'react';
import { graphql } from 'gatsby';

import { 
  MainLayout, 
  Seo,
  DailyIcon,
  ActivitiesBox,
  RecentArticles,
} from '../components';


const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  // const { previous, next } = data;
  const { image } = post.frontmatter;
  const imagePath = image && image.childImageSharp.fixed.src;

  return (
    <>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={imagePath}
      />
      <MainLayout>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
          >
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </article>
        <aside>
          <DailyIcon />
          <ActivitiesBox />
          <RecentArticles />
        </aside>
      </MainLayout>
    </>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "D MMMM YYYY", locale: "ro")
        description
        image {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
